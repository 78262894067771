var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loader-container"},[_c('div',{staticClass:"loader",style:(`
      width:${_vm.size}px;
      height:${_vm.size}px;
      border-width:${_vm.width}px;
      border-top-color: ${_vm.color};
      border-right-color: ${_vm.color};
    `)})])
}
var staticRenderFns = []

export { render, staticRenderFns }