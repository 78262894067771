var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-header"},[_vm._t("header",function(){return [_c('div',{staticClass:"icon",on:{"click":function($event){!_vm.submitting ? _vm.$emit('close') : 0}}},[_c('ModalCloseIcon',{attrs:{"dark":true}})],1)]})],2),_c('div',{staticClass:"modal-body"},[_vm._t("body",function(){return [_c('h4',[_vm._v("ADD A COLLABORATOR")]),_c('div',{staticClass:"existing"},[_c('div',{staticClass:"inputs"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaborator.name),expression:"collaborator.name"}],staticClass:"text-input dark",attrs:{"type":"text","placeholder":"Search Existing Contacts or add new ones...","autocomplete":"no"},domProps:{"value":(_vm.collaborator.name)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (() => {
                        if ((!_vm.valid || _vm.submitting) && !_vm.inviteNew) return;
                        _vm.inviteNew
                          ? _vm.inviteCollaborator(_vm.collaborator.name)
                          : _vm.addCollaborator();
                      }).apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaborator, "name", $event.target.value)}}})])]),(!_vm.fieldsEmpty)?_c('div',{staticClass:"results-container"},[(_vm.resultsAvailable)?_c('div',{staticClass:"results"},_vm._l((_vm.results),function(data,i){return _c('div',{key:i,staticClass:"result",on:{"click":function($event){return _vm.selectResult(data)}}},[(data.avatar_url)?_c('img',{attrs:{"src":data.avatar_url}}):_c('div',{staticClass:"initials"},[_vm._v(" "+_vm._s(_vm._f("initials")(data.name))+" ")]),_c('div',{staticClass:"details"},[_c('span',[_vm._v(_vm._s(data.name))])])])}),0):(_vm.searching)?_c('div',{staticClass:"searching"},[_vm._v(" Searching... ")]):(_vm.canFetchMore)?_c('div',{staticClass:"fetch-more",on:{"click":_vm.loadMore}},[_vm._v(" + Load More Results ")]):(_vm.total === 0)?_c('div',{staticClass:"no-results"},[(_vm.isValidEmail)?_c('button',{staticClass:"w-full",attrs:{"disabled":_vm.inviteLoading},on:{"click":() => {
                        _vm.inviteNew = true;
                        _vm.inviteCollaborator(_vm.collaborator.name);
                      }}},[(!_vm.inviteLoading)?_c('span',{staticClass:"results"},[_c('span',{staticClass:"result"},[_c('span',{staticClass:"initials"},[_vm._v(" "+_vm._s(_vm._f("initials")(_vm.collaborator.name))+" ")]),_vm._v(" "+_vm._s(_vm.collaborator.name)+" ")])]):_c('span',[_vm._v("Loading...")])]):_c('span',[_vm._v("No results found, enter an email to invite to collaborate on Nvoko.")])]):_vm._e()]):_vm._e()]),(_vm.updateHostApplicable)?_c('div',{staticClass:"is-host-container"},[_c('label',{staticClass:"is-host"},[_vm._v("Set Artist as the Host "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isHost),expression:"isHost"}],attrs:{"type":"checkbox","name":"isHost","id":"isHost"},domProps:{"checked":Array.isArray(_vm.isHost)?_vm._i(_vm.isHost,null)>-1:(_vm.isHost)},on:{"change":function($event){var $$a=_vm.isHost,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isHost=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isHost=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isHost=$$c}}}}),_c('span',{staticClass:"checkmark"})])]):_vm._e(),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"primary outline dark",attrs:{"disabled":_vm.submitting},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"primary",attrs:{"disabled":(!_vm.valid || _vm.submitting) && !_vm.inviteNew},on:{"click":() => {
                    if (_vm.inviteNew) {
                      _vm.inviteCollaborator(_vm.collaborator.name);
                    } else {
                      _vm.addCollaborator();
                    }
                  }}},[_vm._v(" "+_vm._s(!_vm.submitting ? !_vm.addingNew ? "Add" : "Invite" : "Submitting...")+" ")])])]})],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }